import { observable, flow } from 'mobx'
import api from '../api'

function getSubcategories(arr, parentUid) {
    var out = []
    for (var i in arr) {
        if (arr[i].parentUid === parentUid) {
            var subcategories = getSubcategories(arr, arr[i].uid)

            if (subcategories.length) {
                arr[i].subcategories = subcategories
            }
            out.push(arr[i])
        }
    }
    return out
}

class PospalStore {
    @observable isSubmitting = false
    @observable error = null
    @observable branches = []
    @observable tickets = []
    @observable products = []
    @observable productCount = 0
    @observable currentProduct = undefined
    @observable categories = []
    @observable rawCategories = []
    @observable optionGroups = []
    @observable count = 0
    @observable users = []
    @observable userCount = []
    @observable userCategories = []

    createPospalBranch = flow(function* (token, shop, values) {
        this.isSubmitting = true
        const {
            name,
            appId,
            appKey,
            baseURL
        } = values
        try {
            yield api.createPospalBranch(token, shop, name, appId, appKey, baseURL)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch(error) {
            console.log(error.response)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    updatePospalBranch = flow(function* (token, shop, id, values) {
        this.isSubmitting = true
        const {
            name,
            appId,
            appKey,
            baseURL
        } = values
        try {
            const response = yield api.updatePospalBranch(token, shop, id, name, appId, appKey, baseURL)
            const index = this.branches.findIndex((b) => {
                return b._id === id
            })
            this.branches[index] = response.data
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch(error) {
            console.log(error.response)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    removePospalBranch = flow(function* (token, shop, id) {
        this.isSubmitting = true
        try {
            yield api.removePospalBranch(token, shop, id)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch(error) {
            console.log(error.response)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    listPospalTicketInRealTime = flow(function* (token, id, startTime, endTime) {
        this.isSubmitting = true
        try {
            const response = yield api.listPospalTicketInRealTime(token, id, startTime, endTime)
            this.tickets = response.data
            this.error = null
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    listPospalTicket = flow(function* (token, shop, id, limit, skip, sort, startTime, endTime) {
        this.isSubmitting = true
        try {
            const response = yield api.listPospalTicket(token, shop, id, limit, skip, sort, startTime, endTime)
            const { tickets, count } = response.data
            this.tickets = tickets
            this.count = count
            this.error = null
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    listPospalBranch = flow(function* (token, shop) {
        this.isSubmitting = true
        try {
            const response = yield api.listPospalBranch(token, shop)
            this.branches = response.data
            this.error = null
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    listPospalCategory = flow(function* (token, id) {
        this.isSubmitting = true
        try {
            const response = yield api.listPospalCategory(token, id)
            const rawCategories = response.data
            this.rawCategories = rawCategories
            this.categories = getSubcategories(rawCategories, 0)
            this.error = null
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    createPospalCategory = flow(function* (token, id, parentCategoryName, categoryName) {
        this.isSubmitting = true
        try {
            yield api.createPospalCategory(token, id, parentCategoryName, categoryName)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch(error) {
            console.log(error)
            this.isSubmitting = false
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            return Promise.reject()
        }
    })

    updatePospalCategory = flow(function* (token, id, categoryUid, parentCategoryName, newCategoryName) {
        this.isSubmitting = true
        try {
            const response = yield api.updatePospalCategory(token, id, categoryUid, parentCategoryName, newCategoryName)
            const index = this.categories.findIndex(o => o.uid === categoryUid)
            this.categories[index] = response.data
            this.error = null
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    deletePospalCategory = flow(function* (token, id, categoryUidsJson) {
        this.isSubmitting = true
        try {
            yield api.deletePospalCategory(token, id, categoryUidsJson)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            return Promise.reject()
        }
    })

    listPospalOptionGroup = flow(function* (token, id) {
        this.isSubmitting = true
        try {
            const response = yield api.listPospalOptionGroup(token, id)
            this.optionGroups = response.data
            this.error = null
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    createPospalOptionGroup = flow(function* (token, id, packageName, enjoyDiscount, attributes) {
        this.isSubmitting = true
        try {
            yield api.savePospalOptionGroup(
                token,
                id,
                '0',
                packageName,
                enjoyDiscount, 
                attributes.map(({key, ...fields}) => fields)
            )
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch(error) {
            console.log(error)
            this.isSubmitting = false
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            return Promise.reject()
        }
    })

    updatePospalOptionGroup = flow(function* (token, id, uid, packageName, enjoyDiscount, attributes) {
        this.isSubmitting = true
        try {
            yield api.savePospalOptionGroup(
                token,
                id,
                uid,
                packageName,
                enjoyDiscount, 
                attributes.map(({key, ...fields}) => fields)
            )
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch(error) {
            console.log(error)
            this.isSubmitting = false
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            return Promise.reject()
        }
    })

    deletePospalOptionGroup = flow(function* (token, id, packageUid) {
        this.isSubmitting = true
        try {
            yield api.deletePospalOptionGroup(token, id, packageUid)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch(error) {
            console.log(error)
            this.isSubmitting = false
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            return Promise.reject()
        }
    })

    listPospalProduct = flow(function* (token, id, pageIndex, pageSize) {
        this.isSubmitting = true
        try {
            const response = yield api.listPospalProduct(token, id, pageIndex, pageSize)
            this.products = response.data.products
            this.productCount = response.data.count
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    getPospalProduct = flow(function* (token, id, productId) {
        this.isSubmitting = true
        try {
            const response = yield api.getPospalProduct(token, id, productId)
            this.currentProduct = response.data.product
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })


    createPospalProduct = flow(function* (token, id, name, barcode, categoryUid, categoryName, sellPrice, buyPrice, isCustomerDiscount, customerPrice, sellPrice2, stock, productTastes) {
        this.isSubmitting = true
        try {
            yield api.createPospalProduct(token, id, name, barcode, categoryUid, categoryName, sellPrice, buyPrice, isCustomerDiscount, customerPrice, sellPrice2, stock, productTastes)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })


    updatePospalProduct = flow(function* (token, id, productId, name, barcode, categoryUid, categoryName, sellPrice, buyPrice, isCustomerDiscount, customerPrice, sellPrice2, stock, productTastes) {
        this.isSubmitting = true
        try {
            yield api.editPospalProduct(token, id, productId, name, barcode, categoryUid, categoryName, sellPrice, buyPrice, isCustomerDiscount, customerPrice, sellPrice2, stock, productTastes)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch(error) {
            console.log(error)
            this.isSubmitting = false
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            return Promise.reject()
        }
    })


    deletePospalProduct = flow(function* (token, id, productId) {
        this.isSubmitting = true
        try {
            yield api.deletePospalProduct(token, id, productId)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch(error) {
            console.log(error)
            this.isSubmitting = false
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            return Promise.reject()
        }
    })

    importPospalProduct = flow(function* (token, id, products) {
        this.isSubmitting = true
        const productsForRequest = products.map(p => {
            const index = this.rawCategories.findIndex(c => c.name === p.categoryName)
            p.categoryUid = this.rawCategories[index].uid
            p.isCustomerDiscount = p.customerPrice ? '1' : '0'
            p.productTastes = []
            return p
        })
        try {
            yield api.importPospalProduct(token, id, productsForRequest)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    listPospalMember = flow(function* (token, shop, id, limit, skip, sort, name, phone, email) {
        this.isSubmitting = true
        try {
            const response = yield api.listPospalMember(token, shop, id, limit, skip, sort, name, phone, email)
            this.users = response.data.pospalUsers
            this.userCount = response.data.count
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    createPospalMember = flow(function* (token, shop, id, categoryName, number, name, point, discount, balance, phone, email) {
        this.isSubmitting = true
        try {
            yield api.createPospalMember(token, shop, id, categoryName, number, name, point, discount, balance, phone, email)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })


    updatePospalMember = flow(function* (token, shop, id, membetId, customerUid, categoryName, name, discount, phone, email, enable, shopperUserId) {
        this.isSubmitting = true
        try {
            yield api.updatePospalMember(token, shop, id, membetId, customerUid, categoryName, name, discount, phone, email, enable, shopperUserId)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch(error) {
            console.log(error)
            this.isSubmitting = false
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            return Promise.reject()
        }
    })

    importPospalMember = flow(function* (token, shop, id, members) {
        this.isSubmitting = true
        try {
            yield api.importPospalMember(token, shop, id, members)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    emailPospalTicket = flow(function* (token, shop, id, ticketSn, email) {
        this.isSubmitting = true
        try {
            yield api.emailPospalTicket(token, shop, id, ticketSn, email)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    getPospalUserCategory = flow(function* (token, shop, id) {
        this.isSubmitting = true
        try {
            const response = yield api.getPospalUserCategory(token, shop, id)
            this.userCategories = response.data
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    linkPospalProduct = flow(function* (token, shop, id, productId, pospalProductId) {
        this.isSubmitting = true
        try {
            yield api.linkPospalProduct(token, shop, id, productId, pospalProductId)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject(this.error)
        }
    })

    unlinkPospalProduct = flow(function* (token, shop, id, linkId) {
        this.isSubmitting = true
        try {
            yield api.unlinkPospalProduct(token, shop, id, linkId)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    updateStockTake = flow(function* (token, branch, selectedProductId, shopperProductId, pospalProductData, shopperProductData) {
        this.isSubmitting = true
        try {
            yield api.updateStockTake(token, branch, selectedProductId, shopperProductId, pospalProductData, shopperProductData)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })
}

export default new PospalStore()
