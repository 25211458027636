import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import messages from '~/messages'
import { Table, Modal, Divider, Button } from 'antd'
import EmailModal from '~/components/emailModal'
import styled from 'styled-components'
import pdfMake from "pdfmake/build/pdfmake"
import fontLoader from '~/lib/fontLoader'
import moment from 'moment'
import { DATE_FORMAT } from '~/constants/format'

const PrintArea = styled.div`
    font-variant: normal;
`

const TableWrapper = styled.div`
    margin: 16px;
`

const Footer = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
`

class OrderPrintModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            emailVisible: false,
            isMakingPDF: false
        }
    }

    getPdf(callback) {
        const { order, shop, intl } = this.props
        var img = new Image()
        img.crossOrigin = 'anonymous'
        img.onload = function () {
            console.log('on load')
            var canvas = document.createElement('CANVAS')
            const ctx = canvas.getContext('2d')
            canvas.height = img.height
            canvas.width = img.width
            ctx.drawImage(img, 0, 0)
            const dataURL = canvas.toDataURL('image/png')

            const productTableData = order ? order.products.map(({ product, quantity, price, choices, detailChoices }, index) => {
                var optionRows
                if (choices && choices.length > 0) {
                    optionRows = choices.map(c => {
                        const index = detailChoices.findIndex(d => d.choice === c)
                        return index > -1
                            ? `- ${c} (${detailChoices[index].subchoices.join(', ')})`
                            : `- ${c}`
                    })
                }
                return optionRows
                    ? (
                        [
                            [
                                { text: product.name },
                                quantity,
                                { text: `$${price}`, alignment: 'right' }
                            ]
                        ].concat(optionRows.map(r => {
                            return [
                                { text: r },
                                '',
                                ''
                            ]
                        })).concat([[
                            '',
                            '',
                            '',
                        ]])
                    )
                    : (
                        [
                            [
                                { text: product.name },
                                quantity,
                                { text: `$${price}`, alignment: 'right' }
                            ]
                        ].concat([[
                            '',
                            '',
                            '',
                        ]])
                    )
            }).flat() : []

            const productSection = 
                {
                    layout: 'lightHorizontalLines',
                    table: {
                        headerRows: 1,
                        widths: ['*', 'auto', 100],

                        body: [
                            [
                                intl.formatMessage({ ...messages.name }),
                                intl.formatMessage({ ...messages.quantity }),
                                { text: intl.formatMessage({ ...messages.price }), alignment: 'right' }
                            ],
                        ].concat(productTableData)
                    }
                }
            

            const reservationTableData = order
                ? order.reservations.map(({ reservation, quantity, price, option }) => {
                    const index = reservation.options.findIndex(o => o._id === option)
                    return index > -1
                        ? [
                            [
                                { text: reservation.product.name },
                                moment(reservation.time).format(DATE_FORMAT),
                                quantity,
                                { text: `$${price}`, alignment: 'right' }
                            ],
                            [
                                { text: `- ${reservation.options[index].name}` },
                                '',
                                '',
                                ''
                            ]
                        ]
                        : [
                            [
                                { text: reservation.product.name },
                                moment(record.reservation.time).format(DATE_FORMAT),
                                quantity,
                                { text: `$${price}`, alignment: 'right' }
                            ]
                        ]
                }).flat()
                : []

            const reservationSection = [
                { text: `${intl.formatMessage({ ...messages.reservationDetail })}:` },
                {
                    layout: 'lightHorizontalLines',
                    table: {
                        headerRows: 1,
                        widths: ['*', 'auto', 100, 100],
                        body: [
                            [
                                intl.formatMessage({ ...messages.name }),
                                intl.formatMessage({ ...messages.time }),
                                intl.formatMessage({ ...messages.quantity }),
                                { text: intl.formatMessage({ ...messages.price }), alignment: 'right' }
                            ],
                        ].concat(reservationTableData)
                    }
                }
            ]

            const definition = {
                content: [
                    {
                        columnGap: 20,
                        columns: [
                            { image: dataURL, width: 50, height: 50 },
                            { text: `${intl.formatMessage({ ...messages.invoice })} - ${shop.name}` }
                        ]
                    },
                    { text: ' ' },
                    { text: `${intl.formatMessage({ ...messages.address })}: ${shop.address}` },
                    { text: `${intl.formatMessage({ ...messages.phoneNumber })}: ${shop.phoneNumber}` },
                    { text: ' ' },
                    { text: `${intl.formatMessage({ ...messages.invoiceId })}: ${order.orderId}` },
                    { text: `${intl.formatMessage({ ...messages.orderDate })}: ${moment(order.createdAt).format(DATE_FORMAT)}` },
                    { text: ' ' },
                    order.products && order.products.length > 0 ? { text: `${intl.formatMessage({ ...messages.productList })}:` } : { text: ' ' },
                    order.products && order.products.length > 0 ? productSection : { text: ' ' },
                    order.reservations && order.reservations.length > 0 ? { text: `${intl.formatMessage({ ...messages.reservationDetail })}:` } : { text: ' ' },
                    order.reservations && order.reservations.length > 0 ? reservationSection : { text: ' ' },
                    { text: ' ' },
                    { text: `${intl.formatMessage({ ...messages.totalAmount })}: ${order.price}`, alignment: 'right' },
                ],
                defaultStyle: {
                    font: 'msyh'
                }
            }

            fontLoader.load()
                .then(() => {
                    const doc = pdfMake.createPdf(definition)
                    callback(doc)

                    canvas = null
                })
        }
        img.src = shop.logo
    }

    handleOnExportPdfClick() {
        this.setState({ isMakingPDF: true })
        const { order } = this.props
        this.getPdf((doc) => {
            doc.download(`invoice_${order.orderId}.pdf`)
            this.setState({ isMakingPDF: false })
        })
    }

    handleOnPrintPdfClick() {
        this.setState({ isMakingPDF: true })
        this.getPdf((doc) => {
            doc.print()
            this.setState({ isMakingPDF: false })
        })
    }

    handleOnEmailPdfClick() {
        this.setState({ emailVisible: true })
    }

    renderTableColumn() {
        const { intl } = this.props
        return [
            {
                title: intl.formatMessage({ ...messages.name }),
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: intl.formatMessage({ ...messages.quantity }),
                dataIndex: 'quantity',
                key: 'quantity',
            },
            {
                title: intl.formatMessage({ ...messages.price }),
                dataIndex: 'totalAmount',
                key: 'totalAmount',
                width: 100
            },
        ]
    }

    renderProductOptions(record) {
        if (record.choices && record.choices.length > 0) {
            return (
                <div>
                    {
                        record.choices.map((c, i) => {
                            const index = record.detailChoices.findIndex(d => d.choice === c)
                            if (index > -1) {
                                return <div key={i}>{`${c} (${record.detailChoices[index].subchoices.join(', ')})`}</div>
                            }
                            return <div key={i}>{c}</div>
                        })
                    }
                </div>
            )
        }
        return <div>-</div>
    }

    renderProductTable() {
        const { intl, order } = this.props
        if (order) {
            const productTable = (
                <Table
                    columns={[
                        {
                            title: intl.formatMessage({ ...messages.productId }),
                            dataIndex: 'product.productId',
                            key: 'productId',
                        },
                        {
                            title: intl.formatMessage({ ...messages.name }),
                            dataIndex: 'product.name',
                            key: 'name',
                        },
                        {
                            title: intl.formatMessage({ ...messages.quantity }),
                            dataIndex: 'quantity',
                            key: 'quantity',
                        },
                        {
                            title: intl.formatMessage({ ...messages.price }),
                            dataIndex: 'price',
                            key: 'price',
                            render: (text) => {
                                return `$${text}`
                            }
                        },
                    ]}
                    dataSource={order.products}
                    pagination={false}
                    defaultExpandAllRows={true}
                    expandedRowRender={record => this.renderProductOptions(record)}
                />
            )

            return order.products.length > 0
                ? (
                    <div>
                        <h4>{`${intl.formatMessage({ ...messages.productList })}:`}</h4>
                        {productTable}
                    </div>
                )
                : null
        } else {
            return null
        }
    }

    renderReservationOptionTable(record) {
        const { intl } = this.props
        const { reservation, option, quantity } = record
        const { options } = reservation

        const index = options.findIndex(o => o._id === option)
        if (index > -1) {
            return (
                <div>
                    <div>{`${intl.formatMessage({ ...messages.optionName })}: ${options[index].name}`}</div>
                    <div>{`${intl.formatMessage({ ...messages.quantity })}: ${quantity}`}</div>
                </div>
            )
        } else {
            return <div>-</div>
        }
    }

    renderReservation() {
        const { intl, order } = this.props
        if (order) {
            const reservationTable = (
                <Table
                    columns={[
                        {
                            title: intl.formatMessage({ ...messages.name }),
                            dataIndex: 'reservation.product.name',
                            key: 'name',
                        },
                        {
                            title: intl.formatMessage({ ...messages.time }),
                            dataIndex: 'reservation.time',
                            key: 'time',
                            render: (text, record) => {
                                return moment(record.reservation.time).format(DATE_FORMAT)
                            }
                        },
                        {
                            title: intl.formatMessage({ ...messages.duration }),
                            dataIndex: 'reservation.duration',
                            key: 'duration',
                            render: (text, record) => {
                                return intl.formatMessage({ ...messages.durationMinute }, { duration: record.reservation.duration })
                            }
                        },
                        {
                            title: intl.formatMessage({ ...messages.userCountMax }),
                            dataIndex: 'reservation.userCountMax',
                            key: 'userCountMax',
                        },
                        {
                            title: intl.formatMessage({ ...messages.userCountMin }),
                            dataIndex: 'reservation.userCountMin',
                            key: 'userCountMin',
                        },
                        {
                            title: intl.formatMessage({ ...messages.price }),
                            dataIndex: 'price',
                            key: 'price',
                            render: (text) => {
                                return `$${text}`
                            }
                        },
                    ]}
                    dataSource={order.reservations}
                    pagination={false}
                    defaultExpandAllRows={true}
                    expandedRowRender={(record) => this.renderReservationOptionTable(record)}
                />
            )
            return order.reservations.length > 0
                ? (
                    <div>
                        <h4>{`${intl.formatMessage({ ...messages.reservationDetail })}:`}</h4>
                        {reservationTable}
                    </div>
                )

                : null
        } else {
            return null
        }
    }

    render() {
        const { intl, visible, isSubmitting, order, shop } = this.props
        const { isMakingPDF } = this.state
        if (!order || !shop) {
            return null
        }
        return (
            <Modal
                visible={visible}
                mask
                maskClosable={false}
                closable={false}
                centered
                width='960px'
                title={intl.formatMessage({ ...messages.invoicePreview })}
                footer={[
                    <Button key="cancel" loading={isSubmitting || isMakingPDF} onClick={() => this.props.onClose()}>
                        {intl.formatMessage({ ...messages.cancel })}
                    </Button>,
                    // <Button key="email" type="primary" loading={isSubmitting || isMakingPDF} onClick={() => this.handleOnEmailPdfClick()}>
                    //     {intl.formatMessage({ ...messages.email })}
                    // </Button>,
                    <Button key="print" type="primary" loading={isSubmitting || isMakingPDF} onClick={() => this.handleOnPrintPdfClick()}>
                        {intl.formatMessage({ ...messages.print })}
                    </Button>,
                    <Button key="export" type="primary" loading={isSubmitting || isMakingPDF} onClick={() => this.handleOnExportPdfClick()}>
                        {intl.formatMessage({ ...messages.export })}
                    </Button>,
                ]}
                okText={intl.formatMessage({ ...messages.download })}
                onOk={() => this.handleOnExportPdfClick()}
                onCancel={() => this.props.onClose()}>
                <PrintArea id='pdf'>
                    <TableWrapper>
                        <h2>{`${intl.formatMessage({ ...messages.invoice })} - ${shop.name}`}</h2>
                        <h4>{`${intl.formatMessage({ ...messages.address })}: ${shop.address}`}</h4>
                        <h4>{`${intl.formatMessage({ ...messages.phoneNumber })}: ${shop.phoneNumber}`}</h4>
                        <Divider />
                        <h4>{`${intl.formatMessage({ ...messages.invoiceId })}: ${order.orderId}`}</h4>
                        <h4>{`${intl.formatMessage({ ...messages.orderDate })}: ${moment(order.createdAt).format(DATE_FORMAT)}`}</h4>

                        {this.renderProductTable()}
                        {this.renderReservation()}
                        <h4>{`${intl.formatMessage({ ...messages.totalPrice })}: $${order.price}`}</h4>
                    </TableWrapper>
                </PrintArea>
                {/* <EmailModal
                    visible={this.state.emailVisible}
                    isSubmitting={isSubmitting}
                    onSubmit={(values, reset) => this.props.onEmail(values, () => {
                        reset()
                        this.setState({ emailVisible: false })
                    })}
                    onClose={() => this.setState({ emailVisible: false })} /> */}
            </Modal>
        )
    }
}

export default injectIntl(OrderPrintModal)