import React from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { withRouter } from "react-router"
import { injectIntl } from 'react-intl'
import { toJS } from 'mobx'
import { Card, Radio, Button, message } from 'antd'
import messages from '~/messages'
import moment from 'moment'
import { Helmet } from 'react-helmet'
import { DATE_FORMAT, DATE_ONLY_FORMAT } from '~/constants/format'

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    padding: 16px;
    
    .ant-card:not(:last-child) {
        margin-bottom: 16px;
    }
`

const RowWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: 16px;

`

const ActionLeftWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
`

const HelpText = styled.div`
    margin-top: 16px;
`

const Title = styled.h3`
    margin-bottom: 16px;
`

@inject('commonStore', 'restaurantStore') @observer
class SettingPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    async componentDidMount() {
        const { commonStore, restaurantStore } = this.props
        const { token } = commonStore
        await restaurantStore.checkStoreAreAllStopped(token)
    }

    async handleOnStopClick() {
        const { intl, commonStore, restaurantStore } = this.props
        const { token } = commonStore
        try {
            await restaurantStore.stopAllStore(token)
            await restaurantStore.checkStoreAreAllStopped(token)
            message.success(intl.formatMessage({ ...messages.success }))
        } catch (e) {
            message.error(intl.formatMessage({ ...messages.failure }))
        }
    }

    async handleOnResumeClick() {
        const { intl, commonStore, restaurantStore } = this.props
        const { token } = commonStore
        try {
            await restaurantStore.resumeAllStore(token)
            await restaurantStore.checkStoreAreAllStopped(token)
            message.success(intl.formatMessage({ ...messages.success }))
        } catch (e) {
            message.error(intl.formatMessage({ ...messages.failure }))
        }
    }

    render() {
        const { intl, restaurantStore } = this.props
        return (
            <Container>
                <Helmet>
                    <title>{intl.formatMessage({ ...messages.setting })}</title>
                </Helmet>
                <Card title={intl.formatMessage({ ...messages.extremeWeatherAction })}>
                    <Title>{`${intl.formatMessage({ ...messages.allStoreStatus })}: ${restaurantStore.areAllStoreStopped ? intl.formatMessage({ ...messages.inactive }) : intl.formatMessage({ ...messages.active })}`}</Title>
                    {
                        restaurantStore.areAllStoreStopped
                            ? (
                                <div>
                                    <Button
                                        type='primary'
                                        loading={restaurantStore.isSubmitting}
                                        onClick={() => this.handleOnResumeClick()}>
                                        {intl.formatMessage({ ...messages.resumeAllStores })}
                                    </Button>
                                    <HelpText>{intl.formatMessage({ ...messages.resumeAllStoreReminder })}</HelpText>
                                </div>
                            )
                            : (
                                <div>
                                    <Button
                                        type='primary'
                                        loading={restaurantStore.isSubmitting}
                                        onClick={() => this.handleOnStopClick()}>
                                        {intl.formatMessage({ ...messages.stopAllStores })}
                                    </Button>
                                    <HelpText>{intl.formatMessage({ ...messages.stopStoreReminder })}</HelpText>
                                </div>
                            )
                    }
                </Card>
            </Container>
        )
    }
}

export default withRouter(injectIntl(SettingPage))